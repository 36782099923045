@import "colors";
@import "values";

body,
html{
    height: 100%;
    width: 100%;
    &>*{
        font-family: "apparat", sans-serif;
    }
}

#root{
    overflow-x: hidden;
}

header{
    color: white;
    display: flex;
    justify-content: space-between;
    padding: clamp(20px, 3vw, 50px) 50px;
    position: absolute;
    width: 100%;
    height: $height-header;
    align-items: center;
    z-index: 99;
    .right{
        display: flex;
        .desktop-nav{
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;
            li{
                position: relative;
                margin-left: 40px;
                &:first-child{
                    margin-left: 0 !important;
                }
                a{
                    text-decoration: none;
                    color: white;
                    font-weight: 600;
                    // text-transform: capitalize;
                    font-size: 20px;
                    &:hover{
                        text-decoration: underline;
                        text-underline-offset: 2px;
                    }
                }
            }
            .sub-menu{
                list-style: none;
                position: absolute;
                padding: 10px;
                margin: 0;
                right: -10px;
                border-radius: 5px;
                // background: red;
                li{
                    text-align: right;
                    margin-bottom: 5px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    a{
                        box-shadow: 0 3px 5px 0 transparentize(black, .9);
                        background: white;
                        text-wrap: nowrap;
                        display: inline-block;
                        padding: 3px 10px;
                        color: $text-blue-light;
                        border-radius: 2px;
                        transition: 200ms;
                        &:hover{
                            text-decoration: none;
                            background: $text-dark;
                            color: white;
                        }
                    }
                }
            }
        }
    }
    &.nav-open{
        // position: fixed;
        .bars{
            div{
                background: $theme-blue !important;
                transform: rotate(45deg) !important;
                top: 50% !important;
                left: 0 !important;
                &:nth-child(2){
                    transform: rotate(-45deg) !important;
                }
            }
        }
    }
    .header-logo{
        height: clamp(40px, 6vw, 70px);
        img{
            height: 100%;
        }
    }
    .menu-hamburger{
        cursor: pointer;
        // fon
        margin-left: 60px;
    }
    .bars{
        position: relative;
        display: block;
        height: 25px;
        width: 30px;
        div{
            height: 20%;
            width: 100%;
            position: absolute;
            left: 0;
            background: white;
            transition: 300ms ease;
            &:first-child{
                top: 0;
            }
            &:nth-child(2){
                top: calc(50% + 1px);
                transform: translateY(-50%);
            }
            &:last-child{
                bottom: -3px;
            }
        }
    }
}
.nav{
    height: 100vh;
    width: 100vw;
    background: none;
    position: fixed;
    z-index: 1000;
    .menu-nav{
        position: fixed;
        // min-height: 600px;
        max-height: 100%;
        overflow: auto;
        width: 100%;
        background: white;
        top: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;
        animation: openNav 300ms ease-in-out;
        transition: 300ms ease-in-out;
        &.close{
            top: -100%;
        }
        header{
            .menu-hamburger{
                color: $text-highlight;
            }
        }
    }
    .backdrop{
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        background: black;
        opacity: .5;
        z-index: 0;
        animation: openNavBackdrop 300ms ease-in-out;
        transition: 300ms ease-in-out;
        &.close{
            opacity: 0;
        }
    }
    nav{
        // height: 100%;
        width: 100%;
        padding: 0 7.5%;
        padding-top: $height-header;
        cursor: default;
        ul{
            list-style: none;
            margin: 0;
            padding: 0 0 0 0;
            width: clamp(270px,27%, 360px);
            position: relative;
            li{
                .sub-menu{
                    position: absolute;
                    left: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    max-height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    z-index: 1;
                    li {
                        padding: 5px 30px 5px 0;
                        a{
                            font-size: 24px;
                            text-wrap: nowrap;
                            color: $sub-menu;
                            &:before{
                                background: $sub-menu !important;
                            }
                            &:hover{
                                color: white;
                                &::before{
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
                a{
                    cursor: pointer;
                    text-decoration: none;
                    font-size: 50px;
                    color: $theme-blue !important;
                    font-weight: bold;
                    display: inline-block;
                    position: relative;
                    &::before{
                        content: "";
                        height: 70%;
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        background: $text-highlight;
                        z-index: -1;
                        opacity: 0;
                    }
                    &:hover{
                        color: white !important;
                        &::before{
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .nav-footer{
        text-align: right;
        padding: 25px 30px;
        .socials{
            display: flex;
            justify-content: end;
            margin-bottom: 20px;
            a{
                // padding: 20px;
                height: 40px;
                width: 40px;
                border: 1px solid $theme-blue;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                color: $theme-blue;
                border-radius: 100px;
                margin-left: 20px;
                transition: 250ms;
                &:hover{
                    background: $theme-blue;
                    color: white;
                }
            }
        }
        button{
            font-size: 20px;
        }
    }
}
@keyframes openNav {
    0%   { top: -100% }
    100% { top: 0 }
}
@keyframes openNavBackdrop {
    0%   { opacity: 0 }
    100% { opacity: .5 }
}

section{
    &.footer{
        text-align: center;
        padding-top: 50px;
        .mobile-copyright{
            order: -1;
            font-size: 14px;
            margin-top: 20px !important;
        }
        .contact{
            &-banner{
                padding: 50px 0;
                color: $text-dark;
                h1{
                    font-size: clamp(35px, 6vw, 80px);
                    font-weight: bold;
                    margin-bottom: 50px !important;
                }
            }
            &-details{
                display: flex;
                justify-content: space-between;
                text-align: left;
                color: $theme-dark;
                width: 100%;
                margin: auto;
                align-items: flex-end;
                padding-bottom: 20px;
                position: relative;
                z-index: 100;
                .email-phone{
                    a{
                        font-size: 36px;
                        text-decoration: none;
                        color: $theme-dark;
                        display: block;
                        font-weight: bold;
                    }
                }
                .connect-with-us{
                    margin-bottom: -45px;
                    p{
                        text-align: right;
                        margin-bottom: 10px !important;
                        font-size: 16px;
                        font-weight: bold;
                    }
                    .socials{
                        display: flex;
                        justify-content: end;
                        a{
                            height: 50px;
                            width: 50px;
                            border: 3px solid $theme-dark;
                            // text-align: center;
                            // line-height: 1.8;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 26px;
                            color: $theme-dark;
                            border-radius: 100px;
                            margin-left: 10px;
                            cursor: pointer;
                            transition: 350ms ease-in-out;
                            &:hover{
                                color: white;
                                background: $theme-dark;
                            }
                        }
                    }
                    .address{
                        font-size: 14px;
                        color: $theme-dark;
                        text-decoration: none;
                        p{
                            font-size: 14px;
                        }
                    }
                }
                .site-map{
                    width: clamp(300px, 40%, 500px);
                    ul.footer-nav{
                        padding: 0 !important;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: repeat(4, 1fr);
                        grid-auto-flow: column;
                        li{
                            list-style: none;
                            padding: 10px 20px 10px 0;
                            a{
                                text-decoration: none;
                                color: $theme-blue-dark;
                                font-weight: bold;
                                font-size: 17px;
                            }
                        }
                    }
                }
            }
        }
        footer{
            position: relative;
            color: $theme-dark;
            display: flex;
            justify-content: space-between;
            padding: clamp(20px, 3vw, 50px) 0;
            width: 100%;
            // height: $height-header;
            padding-top: 0;
            align-items: flex-end;
            z-index: 99;
            .header-logo{
                height: clamp(70px, 10vw, 100px);
                img{
                    height: 100%;
                }
            }
            div{
                text-align: right;
            }
        }
    }
}



.cta.button.cta-brief,
.button-blue-outline{
    padding: 5px 20px;
    border:  1px solid $theme-blue;
    color: $theme-blue;
    background: transparent;
    border-radius: 200px;
    transition: 250ms ease;
    cursor: pointer;
    &:hover{
        background: $theme-blue;
        color: white;
    }
    &:disabled{
        background: $text-gray;
        color: white;
        border-color: transparent;
        opacity: .5;
    }
    &-no-outline{
        border: none;
    }
}
.modal{
    .modal-content{
        padding-right: 30px;
        position: relative;
    }
    &.modal-loading{
        .modal-content{
            position: relative;
            &:after{
                content: "Loading...";
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: transparentize($color: #fff, $amount: 0.25);
                z-index: 1;
                border-radius: .5rem;
                color: $theme-blue;
                font-weight: 600;
                font-size: 1.5em;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .close-button{
        color: $theme-blue;
        font-size: 30px;
        position: absolute;
        right: 15px;
        top: 10px;
        cursor: pointer;
    }
    .modal-body{
        padding: 30px;
    }
}
.modal-brief{
    .brief-wrapper{
        .brief-generator-form{
            .brief-item{
                background: $gray;
                color: $text-gray;
                padding: 20px;
                margin-bottom: 20px;
                label{
                    font-weight: bold;
                    .sub-label{
                        font-weight: normal;
                        display: inline-block;
                        margin-left: 20px;
                        opacity: .8;
                    }
                }
                ul{
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 10px;
                    li{
                        border: 1px solid $text-gray;
                        margin-right: 5px;
                        margin-bottom: 5px;
                        padding: 2px 10px;
                        border-radius: 100px;
                        cursor: pointer;
                        transition: 250ms ease-out;
                        &:hover{
                            background: transparentize($text-gray, .85);
                        }
                        &.active{
                            background: $text-gray;
                            color: white;
                        }
                    }
                }
                input{
                    border: none;
                    border-bottom: 1px solid $text-gray;
                    color: $text-gray;
                    width: 100%;
                    background: none;
                    outline: none;
                    box-shadow: none;
                    padding: 10px;
                }
            }
        }
        .brief-sentence{
            padding: 20px;
            textarea{
                height: 100%;
                width: 100%;
                padding: 10px;
                border: 1px solid $theme-blue;
                border-radius: .5rem;
                resize: none;
                color: $text-dark;
                white-space: pre-wrap;
            }
            label{
                font-weight: bold;
                color: $text-gray;
                .sub-label{
                    font-weight: normal;
                    display: inline-block;
                    margin-left: 20px;
                    opacity: .8;
                }
            }
            h1{
                font-size: 26px;
                margin-bottom: 20px !important;
                display: block;
                text-align: center;
            }
        }
        .brief-details{
            text-align: center;
            .sentence{
                padding: 10px;
                font-size: 40px;
                max-width: 600px;
                margin: 50px auto !important;
                border: 1px solid $theme-blue-dark;
            }
            .final-brief-sentence{
                max-height: 300px;
                width: 80%;
                padding: 20px;
                text-align: left;
                border: 1px solid $text-dark;
                margin: 20px auto 30px;
                overflow: auto;
                white-space: pre-wrap;
            }
            .form-brief{
                display: grid;
                grid-template-columns: 1fr 1fr;
                text-align: left;
                margin-bottom: 30px;
                .formGroup{
                    display: grid;
                    grid-template-columns: 40% 1fr;
                    padding: 5px 10px;
                    align-items: center;
                    label{
                        color: $text-highlight;
                    }
                    input{
                        border: 1px solid $text-highlight;
                        color: $text-dark;
                        border-radius: 100px;
                        padding: 5px 15px;
                        &::-webkit-input-placeholder {
                            opacity: .75;
                            font-style: italic;
                        }
                        
                        &::-moz-placeholder {
                            opacity: .75;
                            font-style: italic;
                        }
                        
                        &::-ms-placeholder {
                            opacity: .75;
                            font-style: italic;
                        }
                        
                        &::placeholder {
                            opacity: .75;
                            font-style: italic;
                        }
                    }
                }
            }
        }
        .buttons{
            text-align: right;
            padding-top: 20px;
            button{
                display: inline-block;
                margin-left: 10px;
            }
        }
        .highlight{
            display: inline-block;
            position: relative;
            &::before{
                content: "";
                height: 70%;
                width: 100%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                background: $text-highlight;
                z-index: -1;
            }
        }
    }
}
.modal-thankyou{
    .check{
        color: green;
        margin-bottom: 20px;
        font-size: 50px;
        border: 2px solid green;
        display: inline-block;
        height: 75px;
        width: 75px;
        border-radius: 100%;
        opacity: 1;
        animation: checkBlink 500ms 3;
    }
    .thank-you{
        padding-top: 30px;
        text-align: center;
    }
    h1{
        font-size: 24px;
        text-align: center;
        color: $text-highlight;
        font-weight: 600;
    }
}
.modal-resume{
    .buttons{
        text-align: right;
    }
    .form-resume{
        margin-bottom: 30px;
        .formGroup{
            display: grid;
            grid-template-columns: 150px 1fr;
            padding: 5px 10px;
            align-items: center;
            label{
                color: $text-highlight;
            }
            .input-file{
                border: 1px solid $text-highlight;
                border-radius: 20px;
                overflow: hidden;
                position: relative;
                height: 150px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-align: center;
                padding: 5px 15px;
                span{
                    font-size: 16px;
                    color: $text-gray;
                    opacity: .75;
                    font-style: italic;
                    &.icon{
                        font-size: 30px;
                    }
                }
                input{
                    cursor: pointer;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    z-index: 2;
                }
            }
            input{
                border: 1px solid $text-highlight;
                color: $text-dark;
                border-radius: 100px;
                padding: 5px 15px;
                width: 100%;
                &::-webkit-input-placeholder {
                    opacity: .75;
                    font-style: italic;
                }
                
                &::-moz-placeholder {
                    opacity: .75;
                    font-style: italic;
                }
                
                &::-ms-placeholder {
                    opacity: .75;
                    font-style: italic;
                }
                
                &::placeholder {
                    opacity: .75;
                    font-style: italic;
                }
            }
        }
    }
}

// START OF RESPONSIVE
@media screen and (max-width: 1080px) and (min-width: 951px) {
    header{
        padding-left: 30px;
        padding-right: 30px;
    }
}
@media screen and (max-width: 1120px) {
    nav{
        padding-left: 60px !important;
        padding-right: 60px !important;
    }
    .nav nav ul li .sub-menu{
        flex-wrap: nowrap !important;
        width: 59vw !important;
        overflow: auto;
    }
    .modal-brief .modal-dialog{
        max-width: 90% !important;
    }
}
@media screen and (max-width: 900px) {
    section{
        &.footer{    
            display: flex;
            flex-direction: column-reverse;
            .contact-details{
                .connect-with-us{
                    margin: 0 !important;
                }
            }
            footer{
                display: flex;
                flex-direction: column;
                align-items: start;
                .header-logo{
                    margin-bottom: 30px;
                }
                div p{
                    text-align: left;
                }
            }
        }
    }
    .modal-brief .brief-wrapper .brief-details .form-brief{
        grid-template-columns: 1fr;
        .formGroup{
            grid-template-columns: 1fr;
            margin-bottom: 10px;
            label{
                margin-bottom: 5px;
                padding-left: 10px;
            }
            input{
                padding: 10px 20px;
            }
        }
    }
}

@media screen and (max-width: 650px) {
    section.footer{
        .contact-details{
            // flex-direction: column !important;
            align-items: start;
            .email-phone a{
                font-size: 25px !important;
            }
            .connect-with-us{
                transform: scale(.8);
            }
        }
    }
    .modal-resume .form-resume .formGroup{
        grid-template-columns: 1fr;
        margin-bottom: 20px;
        label{
            margin-bottom: 10px;
            padding-left: 20px;
        }
    }
}

@media screen and (max-width: 500px) {
    section.footer{
        .contact-details{
            flex-direction: column !important;
            .connect-with-us{
                margin-left: -16px !important;
                p{
                    text-align: left;
                }
                .socials{
                    a{
                        margin-left: 0;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

// END OF RESPONSIVE

// ANIMATION
@keyframes checkBlink {
    0%{opacity: 1;}
    50%{opacity: 0;}
    100%{opacity: 1;}
}