@import "../colors";
@import "../values";

section{
    padding: 20px clamp(30px, 8vw, 130px);
    .title{
        color: $text-blue-dark;
        font-weight: bold;
        font-size: 40px;
    }
    &.home-banner{
        background: transparent;
        padding: 0 !important;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        position: relative;
        color:  white;
        position: relative;
        height: 0;
        padding-top: 60% !important;
        overflow: hidden;
        video{
            width: 100%;
            position: absolute;
            bottom: 0;
        }
        .headline-wrapper{
            position: absolute;
            z-index: 1;
            top: 50%;
            transform: translateY(-50%);
            left: 7%;
            font-weight: bold;
        }
        .headline{
            font-size: clamp(30px, 5.25vw, 80px);
            font-weight: 600;
            // text-transform: uppercase;
            margin-bottom: 20px !important;
            // -webkit-text-stroke-width: 2px;
            // -webkit-text-stroke-color: white;
        }
        .subHeadline{
            font-size: clamp(16px, 1.9vw, 23px);
            margin-bottom: 30px !important;
        }
        .cta{
            background: transparent;
            border-radius: 100px;
            border: 2px solid #006782;
            padding: 5px 30px;
            font-size: 20px;
            overflow: hidden;
            position: relative;
            z-index: 1;
            transition:         0.08s ease-in;
            -o-transition:      0.08s ease-in;
            -ms-transition:     0.08s ease-in;
            -moz-transition:    0.08s ease-in;
            -webkit-transition: 0.08s ease-in;
            &:hover {
                color: white !important;
                &:before {
                    top: 0;
                }
            } 
            &:before {
                content: "";
                position: absolute;
                background: #006782 !important;
                bottom: 0;
                left: 0;
                right: 0;
                top: 100%;
                z-index: -1;
                -webkit-transition: top 0.09s ease-in;
            }
        }
        .mascot{
            position: absolute;
            bottom: -50px;
            right: 100px;
            height:250px;
            width: 250px;
        }
    }  
    // &.home-banner{
    //     background: url("../../images/bg-light-off.png") transparent no-repeat right / cover;
    //     min-height: 100vh;
    //     padding:calc($height-header + 5px) 100px;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: start;
    //     justify-content: center;
    //     position: relative;
    //     color:  white;
    //     position: relative;
    //     &.lightOn-true{
    //         background: url("../../images/bg-light-on.png") transparent no-repeat right / cover;
    //     }
    //     .switch{
    //         bottom: 240px;
    //         right: 350px;
    //         height: 190px;
    //         width: 60px;
    //         background: transparent;
    //         z-index: 1;
    //         position: absolute;
    //         cursor: pointer;
    //     }
    //     .headline{
    //         font-size: clamp(30px, 5vw, 70px);
    //         // font-weight: bold;
    //         // text-transform: uppercase;
    //         margin-bottom: 20px !important;
    //     }
    //     .subHeadline{
    //         font-size: clamp(16px, 1.9vw, 23px);
    //         margin-bottom: 30px !important;
    //     }
    //     .cta{
    //         background: transparent;
    //         border-radius: 100px;
    //         border: 2px solid #006782;
    //         padding: 5px 30px;
    //         font-size: 20px;
    //         overflow: hidden;
    //         position: relative;
    //         z-index: 1;
    //         transition:         0.08s ease-in;
    //         -o-transition:      0.08s ease-in;
    //         -ms-transition:     0.08s ease-in;
    //         -moz-transition:    0.08s ease-in;
    //         -webkit-transition: 0.08s ease-in;
    //         &:hover {
    //             color: white !important;
    //             &:before {
    //                 top: 0;
    //             }
    //         } 
    //         &:before {
    //             content: "";
    //             position: absolute;
    //             background: #006782 !important;
    //             bottom: 0;
    //             left: 0;
    //             right: 0;
    //             top: 100%;
    //             z-index: -1;
    //             -webkit-transition: top 0.09s ease-in;
    //         }
    //     }
    //     .mascot{
    //         position: absolute;
    //         bottom: -50px;
    //         right: 100px;
    //         height:250px;
    //         width: 250px;
    //     }
    // }  
    &.services{
        background: $theme-dark;
        padding: 100px clamp(30px, 8vw, 130px);
        .title{
            color: $text-blue-light;
            text-transform: uppercase;
            margin-bottom: 30px !important;
        }
        .accordion{
            .accordion-item{
                margin-bottom: 20px;
            }
            .trigger-wrapper,
            .body-wrapper{
                grid-template-columns: 35% 1fr;
                padding: 16px 20px;
            }
            button,
            h2, 
            div{
                background: transparent !important;
                border: none !important;
                border-bottom: none !important;
                box-shadow: none !important;
                color: white;
                &::after{
                    display: none;
                }
            }
            .accordion-body{
                padding: 0;
                padding-bottom: 20px;
            }
            button{
                padding: 0 !important;
                &:hover{
                    background: transparentize($text-blue-light, .95) !important;
                }
                &[aria-expanded='true']{
                    .trigger-wrapper .right .open-close .plus-minus div:last-child{
                        transform: translate(-50%, -50%) !important;
                    }
                }
                .trigger-wrapper{
                    width: 100%;
                    display: grid;
                    .left{
                        display: flex;
                        align-items: center;
                        img{
                            margin-right: 15px;
                            height: 20px;
                            width: 20px;
                            object-position: center;
                            object-fit: contain;
                        }
                        .name{
                            color: $text-blue-light;
                            font-weight: 600;
                            font-size: 1.1em;
                            position: relative;
                            .new{
                                color: black;
                                background: red;
                                display: inline-block;
                                font-size: .6em;
                                margin-left: 5px;
                                padding: 2px 5px;
                                position: relative;
                                vertical-align: middle;
                                border-radius: 10px 0 10px 0;
                            }
                        }
                    }
                    .right{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .open-close{
                            .plus-minus{
                                height: 15px;
                                width: 15px;
                                position: relative;
                                div{
                                    position: absolute;
                                    transform: translate(-50%, -50%);
                                    height: 3px;
                                    width: 100%;
                                    top: 50%;
                                    left: 50%;
                                    background: $text-blue-light !important;
                                    transition: 300ms ease;
                                    &:last-child{
                                        transform: translate(-50%, -50%) rotate(-90deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .body-wrapper{
                display: grid;
                padding-top: 10px;
                .services-list{
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    li{
                        padding-bottom: 5px;
                        padding-right: 5px;
                    }
                }
            }

        }
    }
    &.case-studies{
        padding: 0 0 60px !important;
        .title{
            color: $theme-dark;
            font-weight: unset;
            font-size: 30px;
            width: 1020px;
            margin: auto !important;
            padding: 20px;
            font-weight: bold;
        }
        .accordion{
            &-item{
                border: none;
            }
            .accordion-button{
                // &::after{display: none;}
                max-width: 1020px;
                margin: auto;
                outline: none;
                box-shadow: none;
                background: none;
                padding-top: 50px;
                &::after{
                    content: "\f078";
                    position: relative;
                    font-weight: bold;
                    float: right;
                    background: none;
                    font-family: "Font Awesome 6 Free" !important;
                    color: $theme-blue;
                    font-size: 20px;
                }
                &[aria-expanded="true"]{
                    .trigger-wrapper{
                        h2{
                            font-size: 60px;
                        }
                        .industry{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            br{
                                display: none;
                            }
                        }
                        &.overflow{
                            // margin-bottom: -15px;
                            position: relative;
                            z-index: 2;
                        }
                    }
                }
                .trigger-wrapper{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    width: 100%;
                    color: $theme-dark;
                    h2{
                        transition: 300ms ease;
                    }
                    .industry{
                        opacity: .8;
                    }
                }
            }
            .accordion-body{
                padding: 0;
                margin-bottom: 30px;
                min-height: 600px;
                .swiper-slide{
                    height: 100%;
                }
                .next-slide{
                    position: absolute;
                    bottom: 10px;
                    right: 2.5%;
                    display: flex;
                    width: 50%;
                    z-index: 2;
                    color: $theme-blue-dark;
                    align-items: center;
                    .line{
                        width: 70%;
                        height: 4px;
                        background: $theme-blue-dark;
                    }
                    .arrow{
                        display: block;
                        font-size: 26px;
                        padding: 5px;
                        height: 40px;
                        width: 40px;
                        display: flex;
                        justify-content: center;
                        align-self: center;
                        border-radius: 100%;
                        border: 2px solid $theme-blue-dark;
                        margin-left: 10px;
                        cursor: pointer;
                        transition: 200ms ease-in;
                        // background: white;
                        &:hover{
                            background: $theme-light;
                            // border-color: $theme-blue;
                            // color: $theme-blue;
                        }
                        svg{
                            transition: 300ms;
                        }
                    }
                    &.prev-slide{
                        .arrow svg{
                            transform:rotateY(180deg);
                        }
                        // bottom: 0;
                        // right: unset;
                        // left:30px;
                        // justify-content: end;
                    }
                }
                .case-study{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    position: relative;
                    &::before{
                        content: "";
                        height: 50%;
                        width: 50%;
                        background: $theme-blue;
                        position: absolute;
                        z-index: 0;
                        top: 0;
                        left: 0;
                    }
                    .swiper{
                        width: 100%;
                        .swiper-slide{
                            &:nth-child(2){
                                padding: 20px;
                            }
                        }
                    }
                    .media-wrapper{
                        img{
                            height: 100%;
                            width: 100%;
                            object-fit: contain;
                            object-position: center;
                        }
                        .lg-react-element {
                            height: 100%;
                            width: 100%;
                            position: relative;
                        }
                    }
                    .article{
                        padding: 40px;
                        position: relative;
                        z-index: 1;
                        p{
                            margin-bottom: 25px !important;
                        }
                        h4{
                            margin-bottom: 10px !important;
                        }
                        .tags{
                            display: flex;
                            margin-top: 30px;
                            flex-wrap: wrap;
                            span{
                                display: inline-block;
                                color: white;
                                border: 1px solid white;
                                text-transform: uppercase;
                                font-size: 14px;
                                border-radius: 100px;
                                padding: 5px 10px;
                                margin-right: 10px;
                                margin-bottom: 10px;
                                &.blue{
                                    color: $theme-blue;
                                    border-color: $theme-blue;
                                }
                            }
                        }
                    }
                    &.citi{
                        padding-top: 20px;
                        &::before{
                            left: unset;
                            right: 0;
                            width: 55%;
                            height: 90%;
                        }
                        .media-wrapper{
                            img{
                                width: 100%;
                            }
                        }
                    }
                    &.cornetto{
                        &::before{
                            top: 0;
                            width: 45%;
                            height: 100%;
                        }
                        .media-wrapper{
                            text-align: right;
                            display: flex;
                            justify-content: end;
                            align-items: center;
                            height: 100%;
                            min-height: 600px;
                            width: 100%;
                            position: relative;
                            a{
                                max-width: 900px ;
                                width: 90%;
                                position: absolute;
                                top: 50%;
                                right: 0;
                                transform: translateY(-50%);
                            }
                        }
                        .gallery{
                            .case-study-gallery-item{
                                &.left-top{
                                    height: 40%;
                                    right: 37%;
                                    top: 10%;
                                    width: 50%;
                                    img{
                                        object-fit: cover;
                                        object-position: top;
                                    }
                                }
                                &.left-left{
                                    top: 55%;
                                    right: 75%;
                                    width: 25%;
                                }
                                &.left-right{
                                    top: 60%;
                                    right: 37%;
                                    width: 35%;
                                }
                                &.right-most{
                                    width: 33%;
                                    right: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                            }
                        }
                    }
                    &.kumon{
                        &::before{
                            left: unset;
                            right: 0;
                            width: 55%;
                            height: 100%;
                        }
                        .media-wrapper{
                            text-align: right;
                            display: flex;
                            justify-content: end;
                            align-items: center;
                            height: 100%;
                            min-height: 600px;
                            width: 100%;
                            position: relative;
                            .right-most{
                                // height: 90%;
                                width: 50%;
                                height: 80%;
                                object-fit: contain;
                                position: absolute;
                                right: 0;
                                bottom: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                            .left-most{
                                height: 40%;
                                right: 55%;
                                top: 25%;
                                position: absolute;
                            }
                        }
                        .gallery{
                            .case-study-gallery-item{
                                width: 35%;
                                &.top-left{
                                    right: 45%;
                                    top: 2%;
                                }
                                &.top-right{
                                    right: 10%;
                                    top: -2%;
                                    width: 24%;
                                }
                                &.bottom-left{
                                    right: 48%;
                                    top: 65%;
                                    width: 24%;
                                }
                                &.bottom-right{
                                    right: 5%;
                                    top: 35%;
                                    // width: 30%;
                                }
                            }
                        }
                    }
                    &.unmeat{
                        &::before{
                            top: 0;
                            width: 45%;
                            height: 100%;
                        }
                        .media-wrapper{
                            text-align: right;
                            display: flex;
                            justify-content: end;
                            align-items: end;
                            height: 100%;
                            min-height: 600px;
                            width: 100%;
                            position: relative;
                            .right-most{
                                height: 100%;
                                width: 40%;
                                object-fit: contain;
                                position: absolute;
                                right: 0;
                                // max-height: 550px;
                                bottom: 0;
                            }
                            .top-left{
                                width: 50%;
                                position: absolute;
                                bottom: 55%;
                                right: 43%;
                            }
                            .bottom-left{
                                width: 32.5%;
                                // width: 30%;
                                position: absolute;
                                bottom: 10%;
                                right: 51%;
                                // object-fit: contain;
                            }
                        }
                        .gallery{
                            height: 100%;
                            .case-study-gallery-item{
                                // left: 55%;
                                // height: 48% !important;
                                transform: translateY(-50%);
                                width: 90%;
                                height: 70%;
                                top: 50%;
                                right: 0;
                            }
                        }
                    }
                    &.babydove{
                        &::before{
                            left: unset;
                            right: 0;
                            width: 55%;
                            height: 90%;
                        }
                        .media-wrapper{
                            text-align: right;
                            display: flex;
                            justify-content: end;
                            align-items: end;
                            height: 100%;
                            min-height: 600px;
                            width: 100%;
                            position: relative;
                            .left-most{
                                // height: 100%;
                                width: 50%;
                                object-fit: contain;
                                position: absolute;
                                left: 10%;
                                max-height: 550px;
                                top: 0;
                                // transform: translateY(-50%);
                            }
                            .bottom-right{
                                width: 31%;
                                position: absolute;
                                top: 42%;
                                left: 67%;
                            }
                            .top-right{
                                width: 25%;
                                position: absolute;
                                top: 5%;
                                left: 70%;
                            }
                        }

                        .gallery{
                            .case-study-gallery-item{
                                box-shadow: 5px 5px 2px 0 transparentize(black, .97);
                                &.bottom{
                                    height: 40%;
                                    width: 55%;
                                    bottom: 5%;
                                    right: 0;
                                    object-fit: cover;
                                    object-position: center;
                                }
                                &.right{
                                    width: 35%;
                                    top: 3%;
                                    right: 12%;
                                }
                                &.left{
                                    width: 35%;
                                    top: 0;
                                    height: 100%;
                                    left: 5%;
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }
                        }
                    }
                }
                .gallery{
                    overflow-x: auto;
                    width: 100%;
                    display: flex;
                    height: 100%;
                    // max-height: 30vw;
                    position: relative;
                    overflow: visible;
                    .lg-react-element {
                        position: relative;
                        height: 100%;
                        width: 100%;
                        .case-study-gallery-item{
                            display: inline-block;
                            position: absolute;
                            cursor: pointer;
                            img{
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                                object-position: center;
                            }
                        }
                    }
                }
            }
        }
    }
    &.career{
        min-height: 100vh;
        background: url("../../images/career-bg.jpg");
        // background: ;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom right;
        padding-top: 100px;
        padding-bottom: 100px;
        .title{
            color: white;
            font-weight: normal;
            h1{
                font-weight: 600;
                font-size: 75px;
                text-transform: lowercase;
                line-height: .8;
            }
            h3{
                font-size: 35px;
            }
        }
        .cta-resume{
            background: transparent;
            color: white;
            border-radius: 100px;
            border: 1px solid white;
            padding: 5px 20px;
            transition: 300ms ease;
            font-weight: bold;
            margin-top: 30px;
            &:hover{
                background: white;
                color: $theme-blue;
            }
        }
        .select-wrapper{
            position: relative;
            .select-career{
                margin-top: 30px;
                background: white;
                padding: 10px 20px;
                padding-right: 50px;
                border: none;
                outline: none !important;
                border-radius: 100px;
                cursor: pointer;
                -moz-appearance:none; /* Firefox */
                -webkit-appearance:none; /* Safari and Chrome */
                appearance:none;
            }
            &:after{
                font-family: "Font Awesome 6 Free" !important;
                font-weight: bold;
                content: "\f0d7";
                color: $theme-blue;
                position: absolute;
                top: -3px;
                right:17px;
                font-size: 20px;
                height: 30px;
                z-index: 1;
            }
        }
    }
    &.partners{
        position: relative;
        padding-top: 50px;
        padding-bottom: 100px;
        display: grid;
        grid-template-columns: 55% 45%;
        h1{
            font-size: clamp(50px, 5vw, 70px);
            color: $text-blue-light;
            font-weight: 600;
            // -webkit-text-stroke-width: 2px;
            // -webkit-text-stroke-color: $text-blue-light;
        }
        h3{
            padding-right: 30%;
            padding-left: 10%;
            padding-top: 20px;
            font-size:clamp(16px, 1.4vw, 22px);
        }
        .right{
            padding-top: 100px;
            margin-left: -30%;
            width: 115%;
        }
        .partner-logos{
            height: 300px;
            position: relative;
            &.slider{
                position: relative;
                z-index: 0;
                &::after,
                &::before{
                    width: 10%;
                    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.742734593837535) 50%, rgba(255,255,255,0.44021358543417366) 80%, rgba(0,0,0,0) 100%);
                }
                .partners-slider,
                .logos-wrapper,
                img{
                    height: 100%;
                }
                img{
                    max-width: 100%;
                    object-fit: contain;
                }
                .swiper-slide{
                    transition: 300ms linear;
                }
                .swiper-slide-active{
                    margin-left: 35px;
                }
                .arrow{
                    position: absolute;
                    bottom:calc(50% - 20px);
                    right: -75px;
                    z-index: 3;
                    font-size: 30px;
                    padding: 5px;
                    height: 50px;
                    width: 50px;
                    display: flex;
                    justify-content: center;
                    align-self: center;
                    border-radius: 100%;
                    border: 2px solid $text-blue-light;
                    margin-left: 10px;
                    transition: 300ms ease-in;
                    color: $text-blue-light;
                    background: white;
                    padding-top: 8px;
                    transition: 500ms;
                    cursor: pointer;
                    &:after{
                        content: "Next";
                        position: absolute;
                        left: -115%;
                        height: 100%;
                        font-size: 18px;
                        top: 0;
                        align-items: center;
                        display: none;
                    }
                }
            }
            @keyframes leftRight {
                0%{right: 0;}
                50%{right: 5px;}
                100%{right: 0;}
            }
            .marquee {
                margin: 0 auto;
                white-space: nowrap;
                overflow: hidden;
                position: absolute;
                height: 100%;
                width: auto;
            }

            //anim speed
            $animation-speed: 60s;
            .marquee span {
                display: inline-block;
                animation: marquee $animation-speed linear infinite;
                padding-left: 100%;
            }
            
            .marquee2 span {
                animation-delay: calc($animation-speed / 2);
            }
            .marquee3 span{
                padding: 0;
                animation: marquee calc($animation-speed / 2) linear;
                transform: translate(-100%, 0);
            }
            span,
            img{
                display: inline-block;
                height: 100%;
            }
            &::before,
            &::after{
                display: block;
                position: absolute;
                height: 100%;
                width: 30%;
                top: 0;
                left: 0;
                content: "";
                background: rgb(255,255,255);
                background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.742734593837535) 20%, rgba(255,255,255,0.44021358543417366) 52%, rgba(0,0,0,0) 100%);
                z-index: 2;
            }
            &::after{
                right: -5px;
                left: unset;
                transform: rotate(180deg);
            }
        }
        
    }
    &.brief{
        background: url("../../images/brief-background-alt.jpg") transparent no-repeat;
        margin-bottom: 30px;
        position: relative;
        background-size: 100% auto;
        background-position: top;
        padding-top: 50%;
        height: 0;
        h3{
            position: absolute;
            bottom: 100px;
            right: 10%;
            text-align: right;
            span,
            em{
                display: block;
                font-size: 1.5em;
            }
            em{
                font-size: 2em;
                color: $theme-blue;
                font-style: normal;
                font-weight: 600;
                // -webkit-text-stroke-width: 2px;
                // -webkit-text-stroke-color: $theme-blue;
            }
        }
        // .cta-brief{
        //     // background-color: #ddd46c !important;
        //     // border: none !important;
        //     // color: $theme-dark !important;
        //     // top: 300px;
        //     // left: 300px;
        //     // font-size:25px;
        //     // &:hover{
        //     //     background-color: #eee479 !important;
        //     // }display: inline-block;
        //     position: absolute !important;
        //     background-color: #ddd46c !important;
        //     border: none !important;
        //     color: $theme-dark !important;
        //     bottom: 170px;
        //     left: 10%;
        //     font-size:25px !important;
        //     text-shadow: 0 0 2px rgba(0,0,0,.3);
        //     font-family: sans-serif;
        //     position: relative;
        //     overflow: hidden;
        //     padding: 10px 30px !important;
        //     box-shadow:
        //         inset 0 0 2px 0 rgba(255,255,255,.4),
        //         inset 0 0 3px 0 rgba(0,0,0,.4),
        //         inset 0 0 3px 5px rgba(0,0,0,.05),
        //         2px 2px 4px 0 rgba(0,0,0,.25);
        //     &:hover{
        //         background-color: lighten(#ddd46c, 5%) !important;
        //     }
        //     &:before, 
        //     &:after {
        //         content: '';
        //         display: block;
        //         position: absolute;
        //         height: 3px;
        //         // width: 84%;
        //         // left: 8%;
        //         // right: 7%;
        //         left: 2px;
        //         right: 2px;
        //     }
        //     &:before {
        //         top: 0;
        //         border-bottom-left-radius: 4px;
        //         border-bottom-right-radius: 4px;
        //         background: rgba(255,255,255,.6);
        //         box-shadow: 0 1px 2px 0 rgba(255,255,255,.6);
        //     }
        //     &:after {
        //         bottom: 0;
        //         border-top-left-radius: 4px;
        //         border-top-right-radius: 4px;
        //         background: rgba(0,0,0,.15);
        //         box-shadow: 0 -1px 2px 0 rgba(0,0,0,.15);
        //     }
        // }
        .cta-brief{
            position: absolute !important;
            background-color: transparent !important;
            border: none !important;
            bottom: 170px;
            left: 10%;
            padding: 0 !important;
            &:hover{
                filter: brightness(110%) contrast(110%);
            }
            img{
                width: 200px;
            }
        }
    }
}

@keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}

@media screen and (max-width: 1450px) {
    section.case-studies .accordion .accordion-body .case-study.cornetto .gallery{
        // transform: scale(.8);
    }
}

@media screen and (max-width: 1300px) {
    section.case-studies .accordion .accordion-body .case-study.unmeat .media-wrapper .right-most{
        max-height: unset !important;
    }
}
@media screen and (max-width: 1300px) and (min-width: 951px) {
    section.case-studies .accordion .accordion-body .case-study.kumon .gallery .case-study-gallery-item{
        width: 45%;
        &.top-right,
        &.bottom-left{
            width: 32%;
        }
        &.bottom-left{
            right: 60%;
        }
        &.top-left{
            right: 55%;
        }
    }
}
@media screen and (max-width: 1050px) {
    section.services .accordion button .trigger-wrapper{
        grid-template-columns: 1fr;
        .right{
            padding-top: 10px;
            padding-left: 35px;
            .open-close{
                position: absolute;
                right: 10px;
                top: 20px;
            }
        }
    }
    section.services .accordion .body-wrapper{
        grid-template-columns: 1fr;
        padding-left: 75px;
    }

}
@media screen and (max-width: 1250px) and (min-width: 1001px) {
    section.brief h3{
        transform: scale(.8);
        transform-origin: right;
    }
}
@media screen and (max-width: 1000px) {
    section{
        &.partners{
            // grid-template-columns: 1fr !important;

            display: flex;
            flex-direction: column;
            .right{
                margin:  0 !important;
            }
            h1{
                font-size: clamp(40px, 7vw, 70px) !important;
            }
            h3{
                padding-right: 20px !important;
                font-size: clamp(18px, 2vw, 26px) !important;
            }
        }
        &.brief{
            background-position: top center !important;
            margin-bottom: 100px !important;
            // min-height: 85vw !important;
            padding-top: 65% !important;
            h3{
                text-align: center !important;
                right: 0 !important;
                width: 100%;
            }
            .cta-brief{
                bottom: 0 !important;
                left: 50% !important;
                transform: translateX(-50%);
            }
        }
    }
}

@media screen and (max-width: 950px) {
    header{
        padding: 0 30px !important;
        .header-logo{
            height: 60px !important;
        }
    }
    .nav nav ul li .sub-menu{
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
        transform: unset !important;
        padding-left: 20px !important;
    }
    section.home-banner{
        padding-top: 175% !important;
        .headline-wrapper{
            top: calc($height-header + 10px) !important;
            transform: unset !important;
            .headline{
                font-size: 12vw !important;
            }
            .subHeadline{
                font-size: 3.5vw !important;
            }
        }
    }
    section.partners{
        padding-top: 50px;
        .partner-logos{
            &::before{
                left: -30px;
            }
        }
        .swiper{
            margin-left: -30px;
        }
        .right{
            width: 100% !important;
            padding-top: 50px;
        }
        .partner-logos.slider .arrow{
            right: 0;
            bottom: -50px;
            &::after{
                display: flex;
            }
        }
    }
    section.case-studies .accordion .accordion-button[aria-expanded=true] .trigger-wrapper.overflow{
        margin-bottom: 0 !important;
    }
    section.case-studies{
        .swiper-slide{
            position: relative;
        }
        .accordion .accordion-button[aria-expanded=true] .trigger-wrapper h2{
            font-size: 30px;
        }
        .accordion .accordion-body{
            .next-slide{
                top: 550px !important;
                bottom: unset !important;
                justify-content: right !important;
                width: 100% !important;
                // &.prev-slide{
                //     top: unset !important;
                //     bottom: 0 !important;
                // }
            }
            .case-study{
                grid-template-columns: 1fr !important;
                grid-template-rows: 600px 1fr !important;
                padding-top: 0 !important;
                &::before{
                    height: 55% !important;
                    left: 0 !important;
                    top: unset !important;
                    bottom: 0 !important;
                    width: 98% !important;
                }
                .article{
                    padding-right: 10% !important;
                    .tags span{
                        color: white !important;
                        border-color: white !important;
                    }
                }
                .media-wrapper{
                    min-height: unset !important;
                    transform: scale(.9);
                }
                &.citi{
                    .media-wrapper{
                        img{
                            height: 100% !important;
                            width: 100% !important;
                            object-fit: contain;
                            object-position: center;
                        }    
                    }
                }
                &.cornetto{
                    .media-wrapper{
                        justify-content: center;
                        align-items: center;
                        .video-wrapper{
                            text-align: center;
                        }
                        video{
                            width: 100%;
                        }
                        a{
                            height: 80% !important;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            img{
                                object-fit: cover;
                            }
                        }
                    }
                }
                &.kumon{
                    .media-wrapper{
                        .right-most{
                            right: 20px !important;
                        }
                        .left-most{
                            right: 60% !important;
                        }
                    }
                    .gallery .case-study-gallery-item{
                        transform: translateX(-50%);
                        right: unset;
                        bottom: unset;
                        &.top-left{
                            left: 30%;
                            top: 0;
                            width: 33%;
                        }
                        &.top-right{
                            left: 70%;
                        }
                        &.bottom-left{
                            left: 30%;
                            top: 70%;
                        }
                        &.bottom-right{
                            left: 70%;
                            top: 43%;
                            width: 36%;
                        }
                    }
                }
                &.unmeat{
                    .media-wrapper{
                        .top-left{
                            right: 51% !important;
                        }
                        .bottom-left{
                            right: 60%;
                        }
                        .right-most{
                            right: 7.5%;
                        }
                    }
                }
            }
            .gallery{
                flex-direction: column;
                height: 100%;
                max-height: 600px;
                div{
                    height: unset;
                    width: 100%;
                    margin: 0;
                    margin-bottom: 20px;
                    video,
                    img{
                        height: unset;
                        width: 100%;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 800px) {
    section{
        &.career{
            background-position-x: 70% !important;
        }
    }
}
@media screen and (max-width: 750px) {
    section.case-studies .accordion .accordion-body .case-study{
        grid-template-rows: 450px 1fr !important;
    }
    section.case-studies .accordion .accordion-body .next-slide{
        top: 400px !important;
    }
    section.services .accordion .body-wrapper .services-list li{
        padding: 0 20px 20px 0;
    }
    section{
        &.brief{
            // min-height: 100vw !important;
            background-size: 120% !important;
            padding-top: 85% !important;
        }
        &.services .accordion .body-wrapper .services-list{
            grid-template-columns: 1fr !important;
            li{
                // flex: 1 1 auto;
                // padding-right: 30px;
            }
        }
    }
}
@media screen and (max-width: 650px) {
    section.case-studies .accordion .accordion-body .case-study.kumon{
        .gallery .case-study-gallery-item{
            width: 40%;
            &.top-left{
                left: 28%;
            }
            &.top-right{
                left: 72%;
                width: 30%;
            }
            &.bottom-left{
                left: 28%;
                width: 30%;
            }
            &.bottom-right{
                left: 72%;
            }
        }
    }
}
@media screen and (max-width: 550px) {
    section{
        &.brief{
            background-size: 140% !important;
            padding-top: 100% !important;
            h3{
                span{
                    font-size: 1em;
                }
                em{
                    font-size: 1.5em;
                }
            }
            .cta-brief{
                font-size: 20px !important;
                bottom: 30px !important;
                img{
                    width: 150px;
                }
            }
        }
        &.case-studies .accordion .accordion-body .case-study{
            &.babydove{
                .media-wrapper{
                    .left-most{
                        top: 13%;
                        left: 5%;
                    }
                    .bottom-right{
                        width: 35%;
                        left: 63%;
                    }
                    .top-right{
                        left: 66%;
                        width: 29%;
                    }
                }
                .gallery .case-study-gallery-item{
                    &.right{
                        width: 40%;
                        right: 7%;
                    }
                    &.left{
                        height: 95%;
                    }
                }
            }
            &.unmeat{
                .media-wrapper .bottom-left{
                    right: 57%;
                    width: 38.5%;
                    bottom: 5%;
                }
                .gallery .case-study-gallery-item{
                    width: 100%;
                }
            }
            &.kumon{
                .media-wrapper{
                    .left-most{
                        height: unset;
                        width: 45%;
                        right: 58% !important;
                    }
                    .right-most{
                        width: 55%;
                        right: -10px !important;
                        top: 50%;
                    }
                }
                .gallery .case-study-gallery-item{
                    &.top-left,
                    &.bottom-right{
                        width: 40% !important;
                    }
                    &.bottom-left{
                        top: 60% !important;
                    }
                    &.top-right{
                        top: 5%;
                    }
                }
            } 
        }
    }
}

@media screen and (max-width: 450px){
    section{
        &.partners{
            h1{font-size: clamp(18px, 8.5vw, 40px) !important;}
            h3{font-size: clamp(12px, 3.5vw, 24px) !important;}
            .right{
                padding-top: 35px;
                .partner-logos{
                    height: 200px;
                    .arrow{
                        bottom: -35px;
                        font-size: 20px;
                        height: 35px;
                        width: 35px;
                        padding-top: 6px;
                        &::after{
                            font-size: 16px;
                            left: -130%;
                        }
                    }
                }
            }
        }
        &.career .cta-resume{
            background: white;
            color: $theme-blue;
            border: 1px solid $theme-blue;
        }
        &.brief{
            margin-bottom: 50px !important;
            // padding-top: 105% !important;
            h3{
                bottom: 80px;
                em{font-size: 1.25em !important;}
                span{font-size: .75em !important;}
            }
            .cta-brief{

                img{
                    width: 125px !important;
                }
            }
        }
    }
}