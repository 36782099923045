$theme-main: #46d3eb;
$theme-blue: #20bbd5;
$theme-light: #c2f6ff;
$theme-dark: #1d2223;

$gray: #f2f2f2;
$text-gray: #778c90;

$theme-blue-dark: #016884;
$text-blue: #2a727a;
$text-blue-light: #59c7d8;
$text-dark: #41576e;
$text-blue-dark: #286973;
$button-brief: #1db2cc;
$text-highlight: #20bbd5;

$sub-menu: #44d3eb;