*{
    box-sizing: border-box;
}

button{
    border: none;
    color: unset;
}

a{
    text-decoration: unset;
    color: unset;
}



img{
    vertical-align: middle;
}

input[type="number"]{
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.m{
    &-auto{ margin: auto;}
    &b-auto{ margin-bottom: auto;}
    &t-auto{ margin-top: auto;}
    &l-auto{ margin-left: auto;}
    &r-auto{ margin-right: auto;}
    &x-auto{
        margin-top: auto;
        margin-bottom: auto;
    }
    &y-auto{
        margin-left: auto;
        margin-right: auto;
    }
}
@for $i from 0 through 10 {
    .m{
        &-#{$i}{
            margin: $i * .5em !important;
        }
        &b-#{$i}{
            margin-bottom: $i * .5em !important;
        }
        &t-#{$i}{
            margin-top: $i * .5em !important;
        }
        &l-#{$i}{
            margin-left: $i * .5em !important;
        }
        &r-#{$i}{
            margin-right: $i * .5em !important;
        }
        &x-#{$i}{
            margin-left: $i * .5em !important;
            margin-right: $i * .5em !important;
        }
        &y-#{$i}{
            margin-top: $i * .5em !important;
            margin-bottom: $i * .5em !important;
        }
    }
}


.p{
    &-auto{ padding: auto;}
    &b-auto{ padding-bottom: auto;}
    &t-auto{ padding-top: auto;}
    &l-auto{ padding-left: auto;}
    &r-auto{ padding-right: auto;}
    &x-auto{
        padding-top: auto;
        padding-bottom: auto;
    }
    &y-auto{
        padding-left: auto;
        padding-right: auto;
    }
}
@for $i from 0 through 10 {
    .p{
        &-#{$i}{
            padding: $i * .5em !important;
        }
        &b-#{$i}{
            padding-bottom: $i * .5em !important;
        }
        &t-#{$i}{
            padding-top: $i * .5em !important;
        }
        &l-#{$i}{
            padding-left: $i * .5em !important;
        }
        &r-#{$i}{
            padding-right: $i * .5em !important;
        }
        &x-#{$i}{
            padding-left: $i * .5em !important;
            padding-right: $i * .5em !important;
        }
        &y-#{$i}{
            padding-top: $i * .5em !important;
            padding-bottom: $i * .5em !important;
        }
    }
}

.text{
    &-white{color: white;}
    &-black{color: black;}
    &-center{text-align: center;}
    &-left{text-align: left;}
    &-underline{text-decoration: underline;}
    &-bold{font-weight: bold;}
    &-normal{font-weight: normal;}
}

h1,h2,h3,h4,h5,h6,p{
    margin: 0 !important;
}

.fsize-100{
    font-size: 100px;
}

.opacity{
    &-75{opacity: .75;}
    &-50{opacity: .5;}
}

img{
    pointer-events: none;
}

.d-{
    &block{
        display: block !important;
    }
}